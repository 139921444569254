@keyframes inflate {
  0% {
    width: 0px;
    height: 100%;
  }
  50% {
    width: 25vw;
    height: 100%;
  }
  100% {
    width: 0px;
    height: 100%;
  }
}

@keyframes slide-right {
  0% {
    left: 0%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide-self-left {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-100%, 0%);
  }
  100% {
    transform: translate(0, 0);
  }
}
