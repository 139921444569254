@font-face {
  font-family: "NimbusSans";
  src: url("./fonts/NimbusSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NimbusSans";
  src: url("./fonts/NimbusSans-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

html {
  font-size: 15px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "NimbusSans", sans-serif;
  font-size: 1rem;
}

@media screen and (max-width: 576px) {
  /* phone */
  html {
    font-size: 0.85rem;
  }
}

.no-gutter {
  padding: 0;
}
